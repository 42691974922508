import React, { useEffect, useContext } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { motion, useAnimation } from "framer-motion"

import Context from "../../context/"
import ContentWrapper from "../../styles/ContentWrapper"
import SplashScreen from "../splashScreen"
import Theme from "../../styles/Theme"

const StyledSection = styled.section`
  background: ${({ theme }) => theme.colors.background};
  
`

const StyledContentWrapper = styled(ContentWrapper)`
  && {
   display: grid;
   grid-template-columns: 1fr;
   gap: 0px 0px;
   margin-bottom: 1.2rem;
    @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
      margin-bottom: 1.2rem;
    }
    .greetings {
      align-items: center;
      margin-bottom:2rem;
    }
    .emoji {
      margin-left: 0.75rem;
      width: 2.2rem;
      height: 2.2rem;
      @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
        margin-left: 1rem;
        width: 3rem;
        height: 3rem;
      }
    }
    .title {
     display: grid;
    grid-template-columns: 1.0fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-template-areas:
      ".";
    grid-area: Greeting;
    margin-top:2rem;
    margin-bottom: 0;
      @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
        display: grid;
        grid-template-columns: 1.5fr 0.5fr;
        grid-template-rows: 1fr;
        gap: 0px 0px;
        grid-template-areas:
          ". .";
        grid-area: Greeting;
        margin-top:2rem;
        margin-bottom: 0;
      }
    }
    .subtitle {
      display: grid;
      grid-template-columns: 1.0fr 1.0fr;
      grid-template-rows: 1fr;
      gap: 0px 0px;
      grid-template-areas:
        ". .";
      grid-area: Text;
    }
    .description {
      margin-bottom: 2rem;
    }
  }
`

const Hero = ({ content }) => {
  const { frontmatter, body } = content[0].node
  const { isIntroDone } = useContext(Context).state

  // Controls to orchestrate animations of greetings, emoji, social profiles, underlining
  const gControls = useAnimation()
  const eControls = useAnimation()
  const sControls = useAnimation()
  const uControls = useAnimation()

  // Start Animations after the splashScreen sequence is done
  useEffect(() => {
    const pageLoadSequence = async () => {
      if (isIntroDone) {
        eControls.start({
          rotate: [0, -10, 12, -10, 9, 0, 0, 0, 0, 0, 0],
          transition: { duration: 2.5, loop: 3, repeatDelay: 1 },
        })
        await gControls.start({
          opacity: 1,
          y: 0,
          transition: { delay: 0.4 },
        })
        await sControls.start({
          opacity: 1,
          x: 0,
        })
        // Animate underlining to hover state
        await uControls.start({
          boxShadow: `inset 0 -2rem 0 ${Theme.colors.secondary}`,
          transition: { delay: 0.4, ease: "circOut" },
        })
      }
    }
    pageLoadSequence()
  }, [isIntroDone, eControls, gControls, sControls, uControls])
  
  return (
    <StyledSection id="hero">
      {!isIntroDone && <SplashScreen />}
      <StyledContentWrapper>
        
        <motion.div initial={{ opacity: 0, y: 20 }} animate={gControls}>
          <h1 className="title">
            <div className="greetings">
              {frontmatter.greetings}
             
            </div>
            {frontmatter.title}
          </h1>
          <h2 className="subtitle">

              {frontmatter.subtitle}

          </h2>
          <div className="description">
          <h2 >
            <MDXRenderer>{body}</MDXRenderer>
          </h2>
          </div>
        </motion.div>
        <motion.div initial={{ opacity: 0, x: 20 }} animate={sControls}>
        <a href="https://www.linkedin.com/in/everythingoriginal/" target="_blank" rel="noreferrer"><span class="icon-linkedin2"></span></a>
        <a href="https://www.instagram.com/howisbrandon/" target="_blank" rel="noreferrer"><span class="icon-instagram"></span></a>
        <a href="https://www.pinterest.com/howisbrandon/" target="_blank" rel="noreferrer"><span class="icon-pinterest2"></span></a>
        <a href="https://twitter.com/howisbrandon" target="_blank" rel="noreferrer"><span class="icon-twitter"></span></a>
        <a href="https://medium.com/@howisbrandon" rel="noreferrer"><span class="icon-medium"></span></a>
        </motion.div>
      </StyledContentWrapper>
    </StyledSection>
  )
}

Hero.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        body: PropTypes.string.isRequired,
        frontmatter: PropTypes.object.isRequired,
      }).isRequired,
    }).isRequired
  ).isRequired,
}

export default Hero
